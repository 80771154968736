/** Simple translations. I haven't used any external library as it's only few keys that needed to be translated
 * Also this project doesn't need any user language detection, redireciton based on user's language etc
 */
import {LANGUAGE} from './Config'

const translations = {
    "by": {
        "en": "By",
        "pl": "Przez"
    },
    "categories": {
        "en": "Categories",
        "pl": "Kategorie"
    },
    "read_more": {
        "en": "Read more",
        "pl": "Czytaj dalej"
    },
    "open_mobile_menu": {
        "en": "Open mobile menu",
        "pl": "Otwórz mobilne menu"
    },
    "site_title": {
        "en": "Rafał Pydyniak - Blog about Software Development and IT",
        "pl": "Rafał Pydyniak - Programista Java - Blog o programowaniu i szeroko pojętym IT"
    },
    "site_description": {
        "en": "Personal blog of a software engineer passionated with software development, automatization, and devops. Focused mostly on JVM/Liferay related technologies",
        "pl": "Blog osobisty o programowaniu, automatyzacji, tematach Devops. Głównie skupiony wokół języka Java oraz frameworku Liferay"
    }
};

export const getTranslation = (key) => {
    if(translations.hasOwnProperty(key) && translations[key].hasOwnProperty(LANGUAGE)) {
        return translations[key][LANGUAGE];
    } else {
        return key;
    }
}
