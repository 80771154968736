import React, { useRef } from "react"
import styled from "styled-components"
import useClickOutsideElementListener from "../../hooks/useClickOutsideElementListener"
import LinkCustom from "../atoms/LinkCustom"
import logo from "../../images/logo.svg"

const MobileNavWrapper = styled.nav`
  position: fixed;
  z-index: 5;
  top: 0px;
  left: 0px;
  width: 250px;
  padding-top: 2rem;
  height: 100vh;
  background-color: white;
  transition: 0.5s;
  transition-property: transform;
  transform: ${props => (props.drawerOpened ? "none" : "translateX(-100%);")};
  @media (min-width: 768px) {
    display: none;
  }
`

const MobileMenuList = styled.ul`
  list-style: none;
  margin: 0;
`

const MobileMenuListItem = styled.li``

const MobileListLink = props => (
  <MobileMenuListItem>
    <LinkCustom style={{ paddingLeft: "1rem" }} fullWidth={true} to={props.to}>
      {props.children}
    </LinkCustom>
  </MobileMenuListItem>
)

const LogoImage = styled.img`
  margin-bottom: 1rem;
`

export default function MobileNav({ pages, drawerOpened, setDrawerOpened }) {
  const wrapperRef = useRef(null)
  useClickOutsideElementListener(wrapperRef, () => {
    if (drawerOpened) {
      setDrawerOpened(false)
    }
  })
  return (
    <MobileNavWrapper
      ref={wrapperRef}
      id="nav-mobile"
      drawerOpened={drawerOpened}
    >
      <LogoImage width="250" height="125" alt="Blog logo" src={logo} />
      <MobileMenuList>
        {pages.map(page => (
          <MobileListLink key={page.key} to={page.to}>
            {page.title}
          </MobileListLink>
        ))}
      </MobileMenuList>
    </MobileNavWrapper>
  )
}
