import React from "react"
import styled from "styled-components"
import LinkCustom from "../atoms/LinkCustom"
import { faBars } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ScreenReadersText from "../atoms/ScreenReadersText"
import { getTranslation } from "../../utils/LanguagesUtil"

const PrimaryNavWrapper = styled.nav`
  margin-bottom: 0.5rem;
  display: flex;
`

const MenuListWrapper = styled.ul`
  list-style: none;
  margin-left: 2rem;
  margin-bottom: 0;
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`

const ListLink = props => (
  <MenuListItem>
    <LinkCustom to={props.to}>{props.children}</LinkCustom>
  </MenuListItem>
)

const MenuListItem = styled.li`
  display: inline-block;
  margin: 0 1rem 0 0;
`

const MobileNavigationOpenButton = styled.button`
  margin-left: auto;
  background: inherit;
  border: none;
  height: 48px;
  width: 48px;
  padding: 8px;
  @media (min-width: 768px) {
    display: none;
  }
`

export default function PrimaryNav({ pages, drawerOpened, setDrawerOpened }) {
  return (
    <PrimaryNavWrapper id="nav-primary">
      <MenuListWrapper>
        {pages.map(page => (
          <ListLink key={page.key} to={page.to}>
            {page.title}
          </ListLink>
        ))}
      </MenuListWrapper>
      <MobileNavigationOpenButton onClick={e => setDrawerOpened(!drawerOpened)}>
        <FontAwesomeIcon size="lg" icon={faBars} />
        <ScreenReadersText>{getTranslation("open_mobile_menu")}</ScreenReadersText>
      </MobileNavigationOpenButton>
    </PrimaryNavWrapper>
  )
}
