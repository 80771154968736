import React from "react"
import styled from "styled-components"
import LayoutAside from "./LayoutAside"
import LayoutFooter from "./LayoutFooter"
import LayoutHeader from "./LayoutHeader"
import Seo from "./Seo"
import CookieInfo from "./CookieInfo";
import {CookiesProvider} from "react-cookie";

const
Wrapper = styled.div`
  margin: 0 auto;
  padding: 1rem;
  max-width: 1240px;
  height: 100%;
  background-color: white;
`

const MainWrapper = styled.div`
  @media (min-width: 768px) {
    display: flex;
  }
`

const PrimaryWrapper = styled.div`
  padding: 0 1rem;
  @media (min-width: 768px) {
    min-width: 0; /* Workaround for the flex and min-width. See https://dfmcphee.com/flex-items-and-min-width-0/ */
    flex: 3;
    border-right: 1px solid #f1f1f1;
  }
`

const SecondaryWrapper = styled.div`
  margin-top: 3rem;
  @media (min-width: 768px) {
    flex: 1;
    margin-top: 0;
  }
`

export default function Layout({ children, seoTitle, seoDescription }) {
    return (
        <Wrapper>
            <CookiesProvider>
                <Seo title={seoTitle} description={seoDescription}/>
                <LayoutHeader/>
                <MainWrapper>
                    <PrimaryWrapper id="primary">{children}</PrimaryWrapper>
                    <SecondaryWrapper id="secondary" role="complementary">
                        <LayoutAside/>
                    </SecondaryWrapper>
                </MainWrapper>
                <LayoutFooter/>
                <CookieInfo/>
            </CookiesProvider>
        </Wrapper>
    )
}
