import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import styled from "styled-components"
import { categories, getCategoryUrl } from "../../utils/Categories"
import { getTranslation } from "../../utils/LanguagesUtil"
import LinkCustom from "../atoms/LinkCustom"
import { faRss } from "@fortawesome/free-solid-svg-icons"
import {faLinkedin} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Aside = styled.aside`
  padding-left: 1rem;
`

const AsideButtonsWrapper = styled.div`
    margin-bottom: 1rem;
`

const LinkedinLink = styled.a`
    margin-right: 0.75rem;
`

const RssLink = styled.a``

const CategoriesHeader = styled.p`
  font-size: 1.25rem;
  font-weight: bold;
`

const CategoryList = styled.ul`
  margin-left: 0;
`

const CategoryListItem = styled.li`
  list-style: none;
  padding: 0.25rem 0;
  border-bottom: 1px solid #e9e9e9;
  font-size: 0.75rem;
`

const CategoryListEntry = ({ category, articlesPerCategoryCount }) => (
  <CategoryListItem>
    <LinkCustom to={getCategoryUrl(category)}>{category.title}</LinkCustom> (
    {articlesPerCategoryCount[category.key]})
  </CategoryListItem>
)

export default function LayoutAside() {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(filter: { frontmatter: { type: { eq: "Post" } } }) {
          edges {
            node {
              frontmatter {
                categories
              }
            }
          }
        }
      }
    `
  )

  let articlesPerCategoryCount = {}
  allMarkdownRemark.edges
    .flatMap(({ node }) => node.frontmatter.categories)
    .forEach(category => {
      articlesPerCategoryCount[category] =
        1 + (articlesPerCategoryCount[category] || 0)
    })

  return (
    <Aside>
        <AsideButtonsWrapper>
            <LinkedinLink target="_blank" rel="nofollow" href="https://www.linkedin.com/in/pydyniak/">
                <FontAwesomeIcon size="2x" icon={faLinkedin}/>
            </LinkedinLink>
            <RssLink target="_blank" href="/feed.xml">
                <FontAwesomeIcon size="2x" icon={faRss}/>
            </RssLink>
        </AsideButtonsWrapper>
      <CategoriesHeader>{getTranslation("categories")}</CategoriesHeader>
      <CategoryList>
        {categories
          .sort(
            (a, b) =>
              articlesPerCategoryCount[b.key] - articlesPerCategoryCount[a.key]
          )
          .map(category => (
            <CategoryListEntry
              key={category.key}
              category={category}
              articlesPerCategoryCount={articlesPerCategoryCount}
            />
          ))}
      </CategoryList>
    </Aside>
  )
}
