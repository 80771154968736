import React from "react"
import styled from "styled-components"

const Footer = styled.footer`
  margin-top: 3rem;
  display: flex;
`

const CopyrightWrapper = styled.p`
    padding: 0.75rem;
    font-size: 0.75rem;
    margin-left: auto;
`

export default function LayoutFooter() {
  return (
    <Footer>
      <CopyrightWrapper>Copyright: Rafał Pydyniak</CopyrightWrapper>
    </Footer>
  )
}
