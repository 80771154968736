import React from "react"
import styled from "styled-components"
import logo from "../../images/logo.svg"
import LinkCustom from "./LinkCustom"
import ScreenReadersText from "./ScreenReadersText"

const LogoWrapper = styled.div`
  text-align: center;
`

const LogoImage = styled.img`
  margin-bottom: 1rem; 
`

export default function Logo() {
  return (
    <LogoWrapper>
      <LinkCustom to="/" rel="home">
        <LogoImage height="200px" width="400px" alt="Blog logo" src={logo} />
        <ScreenReadersText className="sr-only">
          Go to homepage
        </ScreenReadersText>
      </LinkCustom>
    </LogoWrapper>
  )
}
