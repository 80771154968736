import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ScreenReadersText from "../atoms/ScreenReadersText";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import { useCookies } from 'react-cookie';

const CookieWrapper = styled.div`
    display: ${props => (props.agreedToCookies ? "none" : "block")};
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    border-top: 1px solid black;
    background: white;
    text-align: center;
}`

const CookiesMainContainer = styled.div`
    position: relative;
    padding-top: 2rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
`

const CookiesInfoTextContainer = styled.div`
    width: 90%;
    display: inline-block;
`

const CookiesInfoHideButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    width: 10%;
    font-size: 2rem;
`

const renderDelaySeconds = 0.1;
export default function CookieInfo() {
    const [cookies, setCookie] = useCookies(['cookies-info']);
    const [show, setShow] = useState(false)

    useEffect(
        () => {
            let timer = setTimeout(() => setShow(true), renderDelaySeconds*1000);

            return () => {
                clearTimeout(timer)
            }
        }, [])

    /**
     * Delay used, otherwise the cookie info would show up just for a tiny second as cookie is not read immediately
     */
    if(show && cookies["cookies-info"] !== '1') {
        return (
            <CookieWrapper agreedToCookies={cookies["cookies-info"] === '1'}>
                <CookiesMainContainer>
                    <CookiesInfoTextContainer>
                        Ta strona używa plików cookie w celach statystycznych. Używając strony zgadzasz się na użycie plików
                        cookie.
                    </CookiesInfoTextContainer>
                    <CookiesInfoHideButton onClick={e => setCookie('cookies-info', '1', {
                        path: '/',
                        maxAge: 31536000
                    })}>
                        <FontAwesomeIcon size="xs" icon={faTimes}/>
                        <ScreenReadersText>Zamknij informacje o cookie</ScreenReadersText>
                    </CookiesInfoHideButton>
                </CookiesMainContainer>
            </CookieWrapper>
        )
    } else {
        return null;
    }
}