import { Link } from "gatsby"
import { css } from "styled-components"
import React from "react"

export default function LinkCustom({ to, children, style, rel, fullWidth=false}) {
  return (
    <Link
      css={css`
        display: inline-block;
        text-decoration: none;
        color: inherit;
        width: ${fullWidth ? "100%" : "initial"};

        &:hover {
          text-decoration: none;
        }
      `}
      style={style}
      to={to}
      rel={rel}
    >
      {children}
    </Link>
  )
}
