import React, { useState } from "react"
import styled from "styled-components"
import Logo from "../atoms/Logo"
import MobileNav from "./MobileNav"
import PrimaryNav from "./PrimaryNav"
import { graphql, useStaticQuery } from "gatsby"

const Header = styled.header`
  margin-bottom: 2rem;
  border-bottom: 1px solid black;
`

const MobileMenuOpenedScrim = styled.div`
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: ${props => (props.drawerOpened ? "block" : "none")};
  @media (min-width: 768px) {
    display: none;
  }
`

const mapPages = data => {
  let pages = data.allMarkdownRemark.edges.map(({ node }) => {
    return {
      key: node.id,
      to: node.fields.slug,
      title: node.frontmatter.title,
    }
  })
  pages = [
    {
      key: "1X31kfwem2NQW1E",
      to: "/",
      title: "Home",
    },
    ...pages,
  ]
  return pages
}

export default function LayoutHeader() {
  const [drawerOpened, setDrawerOpened] = useState(false)
  const data = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          sort: { order: ASC, fields: [frontmatter___priority] }
          filter: { frontmatter: { type: { eq: "Page" } } }
        ) {
          edges {
            node {
              id
              frontmatter {
                title
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `
  )

  const pages = mapPages(data)

  return (
    <Header>
      <Logo />
      <PrimaryNav pages={pages} setDrawerOpened={setDrawerOpened} />
      <MobileNav
        pages={pages}
        drawerOpened={drawerOpened}
        setDrawerOpened={setDrawerOpened}
      />
      <MobileMenuOpenedScrim
        drawerOpened={drawerOpened}
      ></MobileMenuOpenedScrim>
    </Header>
  )
}
