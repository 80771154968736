const categories = [
    {key: "automatyzacja", title: "Automatyzacja"},
    {key: "bezpieczenstwo", title: "Bezpieczeństwo"},
    {key: "podpisy-elektroniczne", title: "Podpisy elektroniczne"},
    {key: "java", title: "Java"},
    {key: "liferay", title: "Liferay"},
    {key: "software-architecture", title: "Architektura oprogramowania"}
]

const getCategoryUrl = (category) => {
    return "/category/" + category.key;
}

module.exports.categories = categories;
module.exports.getCategoryUrl = getCategoryUrl;